<template>
  <div class="text-center mx-auto com-home-WhySelect" >
    <h1>为什么选择微海翘财</h1>
    <a-row :gutter="40" v-if="getBrowser == 'pc'">
      <a-col :span="8">
        <div class="item-1">
          <img src=".../../../public/img/consult_icon2.png" />
          <img src=".../../../public/img/consult_icon1.png" />
          <div class="item-title">
            <h3>咨询</h3>
            <span>consulting</span>
          </div>
          <div class="item-content">
            <ul>
              <li>量身定制财务管理策略；</li>
              <li>财务管理流程优化；</li>
              <li>降低企业的财务风险；</li>
            </ul>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="item-2">
          <img src=".../../../public/img/tool_icon3.png" />
          <img src=".../../../public/img/tool_icon2.png" />
          <img src=".../../../public/img/tool_icon1.png" />
          <div class="item-title">
            <h3>工具</h3>
            <span>tool</span>
          </div>
          <div class="item-content">
            <ul>
              <li>近百人的研发团队</li>
              <li>阿里云技术数据更安全</li>
              <li>SaaS模式，降本增效</li>
            </ul>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="item-3">
          <img src=".../../../public/img/service_icon2.png" />
          <img src=".../../../public/img/service_icon1.png" />
          <div class="item-title">
            <h3>服务</h3>
            <span>service</span>
          </div>
          <div class="item-content">
            <ul>
              <li>立体的矩阵式服务，线上线下无缝融合</li>
              <li>客户经理全程跟进，高效解决应用难题</li>
              <li>现场实施培训服务，赋能企业自主应用</li>
            </ul>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="item-4 d-flex">
          <div>
            <img
              src=".../../../public/img/focusing_icon2.png"
              style="width: 26px"
            />
            <img
              src=".../../../public/img/focusing_icon1.png"
              style="width: 40px"
            />
          </div>
          <div>
            <h3>聚焦</h3>
            <p>
              餐易通（翘财）聚焦连锁餐饮企业，辐射连锁服务业；从财务维度，为企业提供一体化的财务综合解决方案
            </p>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="item-5 d-flex">
          <div>
            <img src=".../../../public/img/experience_icon3.png" />
            <img src=".../../../public/img/experience_icon1.png" />
            <img src=".../../../public/img/experience_icon2.png" />
          </div>
          <div>
            <h3>经验</h3>
            <p>
              核心团队皆来自国内大型餐饮上市公司的财务管理团队，丰富的一线管理经验，熟悉餐饮业财务管理痛点，重实操
            </p>
          </div>
        </div></a-col
      >
      <a-col :span="8">
        <div class="item-6 d-flex">
          <div>
            <img src=".../../../public/img/technology_icon2.png" />
            <img src=".../../../public/img/technology_icon1.png" />
          </div>
          <div>
            <h3>技术</h3>
            <p>
              核心技术团队来自腾讯、阿里等大厂，有丰富的SaaS架构经验，为企业提供更稳定、更安全、更流畅的应用系统
            </p>
          </div>
        </div>
      </a-col>
    </a-row>
    <div v-if="getBrowser == 'phone'" style="display:flex;flex-direction:column;height:470px;background:linear-gradient(0deg, #E5F1FB 33.96%, #F1F9FF 99.31%);">
        <div class="bgChoose">
          <div  v-for="(item, index) in list"   :class="{ active2: index == isActive }"  :key="index" @click="changeBox(index)" :style="{background:'url(' + item.src + ')'}">
              <span class="firstSpan">{{item.title}}</span>
              <span class="secondSpan">{{item.desc}}</span>
            </div>
        </div>
        <div style="width:100%;">
         
        <van-swipe type="card"   :loop="false" @change="onChange" ref="swiper" style="width:100%;">
          <van-swipe-item >
            <img src="../../assets/zixun.png" alt="">
            <span>
              <img class="img1" src="../../assets/left.png" alt="">
                <br>量身定制财务管理策略；财务管理流程优化；降低企业的财务风险；
              <img class="img2" src="../../assets/right.png" alt="">
            </span>
          </van-swipe-item>   
          <van-swipe-item >
            <img src="../../assets/gongju.png" alt="">
             <span>
              <img class="img1" src="../../assets/left.png" alt="">
              <br>近百人的研发团队,阿里云技术数据更安全,SaaS模式,降本增效
              <img class="img2" src="../../assets/right.png" alt="">
             </span>
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../assets/fuwu.png" alt="">
            <span>
              <img class="img1" src="../../assets/left.png" alt="">
              <br>立体的矩阵式服务，线上线下无缝融合;客户经理全程跟进，高效解决应用难题；现场实施培训服务，赋能企业自主应用
              <img class="img2" src="../../assets/right.png" alt="">
            </span>
          </van-swipe-item>
          <van-swipe-item >
            <img src="../../assets/jujiao.png" alt="">
            <span>
              <img class="img1" src="../../assets/left.png" alt="">
                <br>餐易通（翘财）聚焦连锁餐饮企业，辐射连锁服务业；从财务维度，为企业提供一体化的财务综合解决方案
              <img class="img2" src="../../assets/right.png" alt="">
            </span>   
          </van-swipe-item>
          <van-swipe-item >
             <img src="../../assets/jingyan.png" alt="">
             <span>
               <img class="img1" src="../../assets/left.png" alt="">
                <br>核心团队皆来自国内大型餐饮上市公司的财务管理团队，丰富的一线管理经验，熟悉餐饮业财务管理痛点，重实操
               <img class="img2" src="../../assets/right.png" alt="">
            </span>   
          </van-swipe-item>
          <van-swipe-item >
             <img src="../../assets/jishu.png" alt="">
             <span>
              <img class="img1" src="../../assets/left.png" alt="">
                 <br>核心技术团队来自腾讯、阿里等大厂,有丰富的SaaS架构经验,为企业提供更稳定、更安全、更流畅的应用系统
              <img class="img2" src="../../assets/right.png" alt="">
            </span>   
            </van-swipe-item>
        </van-swipe>
        </div>
    </div>

  </div>
</template>
<script>
import zixunImg from "../../assets/咨询(1).png";
import gongjuImg from "../../assets/工具(1).png";
import fuwuImg from "../../assets/服务(1).png";
import jingyanImg from "../../assets/聚焦(1).png";
import jujiaoImg from "../../assets/经验(1).png";
import jushuImg from "../../assets/技术(1).png";
export default {
  data() {
    return {
      list: [
          {
            title: "咨询",
            desc: "consulting",
            src:zixunImg
          },
          {
            title: "工具",
            desc: "tool",
            src:gongjuImg
          },
          {
            title: "服务",
            desc: "service",
            src:fuwuImg
          },
          {
            title: "聚焦",
            desc: "Focus",
            src:jujiaoImg
          },
          {
            title: "经验",
            desc: "experience",
            src:jingyanImg
          },
          {
            title: "技术",
            desc: "technology",
            src:jushuImg
          },
        ],
      isActive : 0,
      changenav:null,
    };
  },
  methods:{
    onChange(e){
      this.isActive = e;
    },
    changeBox(val){
       this.$refs.swiper.swipeTo(val);
       this.isActive = val;
    },
  }
  
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 320px) and (max-width: 1024px) {
 .com-home-WhySelect {
  width: 100%;
  // padding-bottom: 80px;
  h1 {
    padding: 40px;
    font-size:24px;
  }
  .bgChoose{
    width:100%;
    margin-top:1rem;
    height:12rem;
    background: url(../../assets/whyChoose.png) ;
    background-size: cover;
    position: relative;
   
    div{
      width:60px;
      height:60px;
      // border-radius:20px;
      display: flex;
     
      font-size:18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background: none;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      .firstSpan{
        position:absolute;
        bottom:52%;
        left:29%; 
        font-size: 13px;
        background: none;
        color: #ffffff;
        text-align: justify;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        opacity: 0.8;
      }
      .secondSpan{
        position:absolute;
        bottom:34%;
        left:10%; 
        font-size: 7px;
        background: none;
        color:white;
        opacity: 0.8;
      }  
    }
    :first-child{
      position:absolute;
      bottom:12%;
      left:40%; 
      // background: linear-gradient(-45deg, #009d90 0%, #02c4b9 100%);
    }
    :nth-child(2){
        position:absolute;
        bottom:34%;
        right:9%;
        // background: linear-gradient(-45deg, #3587e8 0%, #43a6fb 100%);
    }
     :nth-child(3){
        position:absolute;
        top:-2%;
        right:18%;
        // background: linear-gradient(-45deg, #e56551 0%, #f5896f 100%);
    }
    :nth-child(4){
        position:absolute;
        top:-14%;
        right:45%;
        // background: linear-gradient(-45deg, #23b2a0 0%, #52d0b2 100%);
    }
    :nth-child(5){
        position:absolute;
        top:-4%;
        left:15%;
        // background: linear-gradient(-45.4deg, #0a99e4 0%, #19deed 100%);
    }
    :nth-child(6){
        position:absolute;
        bottom:36%;
        left:7%;
        // background: linear-gradient(-45deg, #e4518f 0%, #f29aac 100%);
    }
    .active2{
      transform:scale(1.6,1.6);
      span{
         opacity: 1;
      }
    }

    .zixun{
      position:absolute;
      bottom:5%;
      left:40%; 
      background: linear-gradient(-45deg, #009d90 0%, #02c4b9 100%);
    }
    .gongju{
        position:absolute;
        bottom:16%;
        right:5%;
        background: linear-gradient(-45deg, #3587e8 0%, #43a6fb 100%);
    }
    .jishu{
        position:absolute;
        bottom:15%;
        left:4%;
        background: linear-gradient(-45deg, #e4518f 0%, #f29aac 100%);
    }
    .fuwu{
        position:absolute;
        top:3%;
        right:10%;
        background: linear-gradient(-45deg, #e56551 0%, #f5896f 100%);
    }
    .jujiao{
        position:absolute;
        top:-10%;
        right:42%;
        background: linear-gradient(-45deg, #23b2a0 0%, #52d0b2 100%);
    }
    .jingyan{
        position:absolute;
        top:3%;
        left:10%;
        background: linear-gradient(-45.4deg, #0a99e4 0%, #19deed 100%);
    }
  
  }
  /deep/ .van-swipe .van-swipe-item{
   
    img{
      width:100%;
      height:210px;
    }
    span{
      position:absolute;
      left:5%;
      top:22%;
      padding:0 32px;
      color: #041334;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.438px;
      .img1{
        height:12px;
        width:12px;
        position:absolute;
        left:13px;
        top:12px;
      }
      .img2{
        height:12px;
        width:12px;
        text-align:right;
        position:absolute;
        right:12%;
        margin-top:40px;
      }
    }

  }
  
}
}
@media screen and (min-width: 1024px) {
  .com-home-WhySelect {
  width: 1200px;
  height: 623px;
  margin-top: 40px;
  padding-bottom: 80px;
  h1 {
    padding: 40px;
  }
  .item-1,
  .item-2,
  .item-3 {
    width: 374px;
    height: 154px;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      transition: 0.5s;
    }
    .item-title,
    .item-content {
      position: absolute;
      transition: 0.5s;
      padding: 32px 40px;
      text-align: left;
    }
    .item-title {
      color: #ffffff;
      h3 {
        font-family: PingFangSC-Medium;
        font-size: 22px;
        letter-spacing: 0.37px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 8px;
        padding-top: 10px;
      }
      span {
        opacity: 0.8;
        font-size: 14px;
        letter-spacing: 0.23px;
        font-weight: 400;
      }
    }
    .item-content {
      margin-top: 154px;
      color: white;
      ul {
        padding-left: 18px;
        li {
          padding: 5px 0;
        }
      }
    }
    &:hover {
      .item-title {
        margin-top: -154px;
      }
      .item-content {
        margin-top: 0;
      }
    }
  }
  .item-1 {
    background-image: linear-gradient(90deg, #00a497 22%, #03c5ba 100%);
    img {
      &:nth-child(1) {
        margin-top: 90px;
        margin-left: 120px;
      }
      &:nth-child(2) {
        margin-top: 30px;
      }
    }
    &:hover {
      img {
        opacity: 0.5;
        &:nth-child(1) {
          margin-left: 130px;
        }
        &:nth-child(2) {
          margin-left: -10px;
        }
      }
    }
  }
  .item-2 {
    background-image: linear-gradient(109deg, #3587e8 20%, #44a7fb 99%);
    img {
      &:nth-child(1) {
        margin-top: 40px;
        margin-left: 40px;
      }
      &:nth-child(2) {
        margin-top: 60px;
        margin-left: -10px;
      }
      &:nth-child(3) {
        margin-top: 20px;
        margin-left: 50px;
      }
    }
    &:hover {
      img {
        opacity: 0.5;
        &:nth-child(1) {
          margin-top: 25px;
          margin-left: 37px;
        }
        &:nth-child(3) {
          margin-left: 60px;
        }
      }
    }
  }
  .item-3 {
    background-image: linear-gradient(130deg, #ea6e5a 21%, #f68c71 100%);
    img {
      &:nth-child(1) {
        margin-top: 80px;
        margin-left: 30px;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    &:hover {
      img {
        opacity: 0.5;
        &:nth-child(1) {
          margin-left: 20px;
        }
        &:nth-child(2) {
          margin-left: 30px;
        }
      }
    }
  }

  .ant-col:nth-child(n + 4) {
    margin-top: 40px;
    transition: 0.5s;
    &:hover {
      margin-top: 20px;
      .d-flex {
        height: 228px;
        box-shadow: 0 0 10px #ddd;
      }
    }
  }

  .item-4,
  .item-5,
  .item-6 {
    padding: 40px;
    width: 374px;
    height: 198px;
    background: #ffffff;
    transition: 0.5s;
    div {
      text-align: left;
      &:nth-child(1) {
        width: 80px;
      }
      &:nth-child(2) {
        flex: 1;
      }
      h3 {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #202121;
        letter-spacing: 0.3px;
        line-height: 26px;
        font-weight: 500;
      }
      p {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #949898;
        letter-spacing: 0.44px;
        line-height: 21px;
        font-weight: 400;
      }
    }
    img {
      transition: 0.5s;
    }
  }

  .item-4 {
    img {
      position: absolute;
      &:nth-child(1) {
        margin-left: 20px;
        margin-top: 10px;
      }
      &:nth-child(2) {
        margin-top: 15px;
      }
    }

    &:hover {
      img {
        &:nth-child(1) {
          margin-top: 0;
        }
        &:nth-child(2) {
          margin-left: -10px;
        }
      }
    }
  }

  .item-5 {
    img {
      width: 44px;
      position: absolute;
      &:nth-child(1) {
        width: 30px;
        margin-left: 7px;
        margin-top: 13px;
      }
      &:nth-child(2) {
        margin-top: 20px;
        z-index: 1;
        height: 30px;
      }
      &:nth-child(3) {
        margin-top: 35px;
      }
    }
    &:hover {
      img {
        &:nth-child(2) {
          height: 20px;
        }
      }
    }
  }

  .item-6 {
    img {
      position: absolute;
      &:nth-child(1) {
        width: 30px;
        margin-top: 35px;
      }
      &:nth-child(2) {
        width: 57px;
        margin-top: 15px;
      }
    }

    &:hover {
      img {
        &:nth-child(2) {
          margin-left: 5px;
          margin-top: 10px;
        }
      }
    }
  }
}
}

</style>